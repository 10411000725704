import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import { history } from '@router/utils';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { mockApi } from './mockApi';

mockApi();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <Router history={history}>
        <App />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
