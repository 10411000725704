/* eslint-disable global-require */

export const mockApi = (): void => {
  const { NODE_ENV, REACT_APP_MOCK_API } = process.env;
  if (NODE_ENV === 'development' && REACT_APP_MOCK_API === 'true') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mocks/browser');
    worker.start();
  }
};
