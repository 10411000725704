import React from 'react';

export const AuthContext = React.createContext<{
  isLogged: boolean;
  deviceSerialNumber?: string;
  selectDevice: (serialNumber: string) => void;
  login: () => void;
  logout: () => void;
}>({
  isLogged: false,
  deviceSerialNumber: undefined,
  selectDevice: () => {},
  login: () => {},
  logout: () => {},
});

export const AppearanceContext = React.createContext<{
  pageTitle: string;
  pageHeaderExtra?: React.RefObject<React.ReactNode>;
  pageKey: string;
  setPageTitle: (value: string) => void;
  setPageKey: (value: string) => void;
  setPageHeaderExtra: (extra: React.ReactNode) => void;
}>({
  pageTitle: '',
  pageKey: '',
  setPageKey: () => {},
  setPageTitle: () => {},
  setPageHeaderExtra: () => {},
});
