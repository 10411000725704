import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { layoutRouteList } from '@router/utils';

function AppRouter(): JSX.Element {
  return (
    <Switch>
      {layoutRouteList.map((route) => (
        <Route key={route.path} path={route.path} component={route.component} />
      ))}
      <Route component={() => <Redirect to='/login' />} />
    </Switch>
  );
}

export default AppRouter;
