export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN) || '';
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN) || '';
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const removeAccessToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN);
};

export const clearTokens = (): void => {
  removeAccessToken();
  removeRefreshToken();
};

const storageService = {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  clearTokens,
};

export default storageService;
