import { IRoute, IRouteBase } from './type';

export function flattenRoute(
  routeList: IRoute[],
  deep: boolean,
  auth: boolean,
  parentPath?: string,
): IRoute[] {
  const result: IRoute[] = [];
  for (let i = 0; i < routeList.length; i += 1) {
    const route = routeList[i];
    const accessiblePath = `${parentPath || ''}${route.path}`;

    result.push({
      ...route,
      path: accessiblePath,
      auth: typeof route.auth === 'undefined' ? auth : route.auth,
    });

    if (route.children && deep) {
      result.push(...flattenRoute(route.children, deep, auth, accessiblePath));
    }
  }

  return result;
}

export function getLayoutRouteList(routes: IRoute[]): IRoute[] {
  return flattenRoute(routes, false, false);
}

export function getSystemRouteList(routes: IRoute[]): IRoute[] {
  const routeList = routes.filter((route) => route.path === '/');
  if (routeList.length > 0) {
    return flattenRoute(routeList, true, false);
  }
  return [];
}

export function getDashBoardRouteList(
  routes: IRoute[],
  dashboardRootPath: string,
): IRoute[] {
  const routeList = routes.filter((route) => route.path === dashboardRootPath);
  if (routeList.length > 0) {
    return flattenRoute(routeList, true, false).filter(
      (route) => !!route.component,
    );
  }
  return [];
}

export function getNavRouteList(
  routes: IRoute[],
  dashboardRootPath: string,
): IRoute[] {
  const routeList = routes.filter((route) => route.path === dashboardRootPath);
  if (routeList.length > 0) {
    const dashboardRoute = routeList[0];
    return (
      dashboardRoute.children?.map((item) => ({
        ...item,
        path: `${dashboardRootPath}${item.path}`,
      })) || []
    );
  }
  return [];
}

export const getPageTitle = (
  routList: IRouteBase[],
  pathname: string,
): string => {
  return routList.find((item) => item.path === pathname)?.meta?.title || '';
};

export const getPageKey = (
  routList: IRouteBase[],
  pathname: string,
): string => {
  const matchRoute = routList.find((item) => item.path === pathname);
  return matchRoute?.parentKey || matchRoute?.key || '';
};

export const isChildNavExist = (routeList?: IRoute[]): boolean => {
  return (
    typeof routeList !== 'undefined' &&
    routeList?.filter((item) => item.nav).length !== 0
  );
};
