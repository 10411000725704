import { createBrowserHistory } from 'history';
import {
  getDashBoardRouteList,
  getLayoutRouteList,
  getNavRouteList,
  getSystemRouteList,
} from '@deltron/common/router';
import routes from './config';

const dashboardRootPath = '/dashboard';

export const history = createBrowserHistory();

export const layoutRouteList = getLayoutRouteList(routes);

export const systemRouteList = getSystemRouteList(routes);

export const dashBoardRouteList = getDashBoardRouteList(
  routes,
  dashboardRootPath,
);

export const navRouteList = getNavRouteList(routes, dashboardRootPath);
