import React from 'react';
import {
  ClusterOutlined,
  DashboardOutlined,
  LineChartOutlined,
  PieChartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { IRoute } from '@deltron/common/router';

const routes: IRoute[] = [
  {
    key: 'login',
    path: '/login',
    component: React.lazy(() => import('../features/User/Login/Login')),
    nav: false,
  },
  {
    key: 'reset-password',
    path: '/reset-password',
    component: React.lazy(
      () => import('../features/User/ResetPassword/ResetPassword'),
    ),
    nav: false,
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    component: React.lazy(() => import('../layouts/UserDashboardLayout')),
    nav: false,
    meta: {
      title: '道创云',
    },
    children: [
      {
        key: 'notAuthorized',
        path: '/403',
        component: React.lazy(
          () => import('@deltron/common/pages/Unauthorized'),
        ),
        nav: false,
      },
      {
        key: 'dashboard-overview',
        path: '/overview',
        component: React.lazy(
          () => import('../features/Dashboard/Overview/Overview'),
        ),
        meta: {
          title: '仪表盘',
          icon: <DashboardOutlined />,
        },
        nav: true,
      },
      {
        key: 'dashboard-auth',
        path: '/auth',
        component: React.lazy(() => import('../features/Dashboard/Auth/Auth')),
        meta: {
          title: '认证申请',
        },
        nav: false,
      },
      {
        key: 'dashboard-data-report',
        path: '/report',
        component: React.lazy(
          () => import('../features/Dashboard/Report/ReportList'),
        ),
        meta: {
          title: '数据报表',
          icon: <PieChartOutlined />,
        },
        nav: true,
        children: [
          {
            parentKey: 'dashboard-data-report',
            key: 'dashboard-data-details',
            path: '/:id',
            component: React.lazy(
              () => import('../features/Dashboard/Report/ReportDetails'),
            ),
            meta: {
              title: '报表详情',
              icon: <PieChartOutlined />,
            },
            nav: false,
            withPageHeaderExtra: true,
          },
        ],
      },
      {
        key: 'dashboard-data-analysis',
        path: '/analysis',
        component: React.lazy(
          () => import('../features/Dashboard/Analysis/DataAnalysis'),
        ),
        meta: {
          title: '数据分析',
          icon: <LineChartOutlined />,
        },
        nav: true,
      },
      {
        key: 'dashboard-devices',
        path: '/device',
        component: React.lazy(
          () => import('../features/Dashboard/Device/DeviceList'),
        ),
        meta: {
          title: '设备列表',
          icon: <ClusterOutlined />,
        },
        nav: true,
        children: [
          {
            parentKey: 'dashboard-devices',
            key: 'dashboard-device-details',
            path: '/:deviceSerialNumber',
            component: React.lazy(
              () => import('../features/Dashboard/Device/DeviceDetails'),
            ),
            meta: {
              title: '设备详情',
              icon: <PieChartOutlined />,
            },
            nav: false,
          },
        ],
      },
      {
        key: 'dashboard-profile',
        path: '/profile',
        component: React.lazy(
          () => import('../features/Dashboard/User/Profile'),
        ),
        meta: {
          title: '账户设置',
          icon: <UserOutlined />,
        },
        nav: true,
        commonPage: true,
      },
      {
        key: 'notFoundPage',
        path: '/*',
        component: React.lazy(() => import('@deltron/common/pages/NotFound')),
        nav: false,
      },
    ],
  },
];

export default routes;
