export const getUrl = (url: string): string => `/api/${url}`;

export const makeResponse = ({
  data = {},
  code = 10000,
  msg = '',
}: {
  data?: unknown;
  code?: number;
  msg?: string;
}): {
  data?: unknown;
  code?: number;
  msg?: string;
} => ({
  code,
  msg,
  data,
});
