/* eslint-disable no-underscore-dangle */
import applyCaseMiddleware from 'axios-case-converter';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createContext, useContext } from 'react';
import qs from 'qs';
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { notification, PaginationProps } from 'antd';
import {
  BaseResponse,
  BaseResponseWithPagination,
} from '../interfaces/request';
import * as storageService from '../storage';

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 3000,
};

export const instance = Axios.create(axiosConfig);

const requestInterceptor = (requestConfig: AxiosRequestConfig) => {
  let token = storageService.getAccessToken();

  if (requestConfig.url === '/user/refresh') {
    token = storageService.getRefreshToken();
  }

  if (token && requestConfig.headers) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers = Object.assign(requestConfig.headers, {
      Authorization: token,
    });
  }

  return requestConfig;
};

const responseInterceptor = (resopnseConfig: AxiosRequestConfig) =>
  resopnseConfig.data;

const handleResponseError = (error: any) => {
  // 程序错误的话提前处理
  if (typeof error.response === 'undefined') {
    notification.error({
      message: '请求错误',
      description: '请稍后重试',
    });
    return Promise.reject(error);
  }

  const { status } = error.response;
  const originalRequest = error.config;

  if (status === 401 && originalRequest.url === '/user/refresh') {
    storageService.clearTokens();
    window.location.replace('/login');

    return Promise.reject(error);
  }

  if (status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return instance.post('/user/refresh').then((res) => {
      storageService.setAccessToken(res.data.accessToken);

      return instance(originalRequest);
    });
  }

  if (error.response.data?.code === 10003) {
    window.location.replace('/dashboard/403');
  } else if (error.response && error.response.status) {
    notification.error({
      message: `请求错误: `,
      description: error.response.data?.msg || '请稍后重试',
    });
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(requestInterceptor);

instance.interceptors.response.use(responseInterceptor, handleResponseError);

export const downloadRequest = Axios.create(axiosConfig);

downloadRequest.interceptors.request.use(requestInterceptor);
downloadRequest.interceptors.response.use(
  (response) => response,
  handleResponseError,
);

const request = applyCaseMiddleware(instance);

export const AxiosContext = createContext(
  new Proxy(request, {
    apply: () => {
      throw new Error('You must wrap your component in an AxiosProvider ');
    },
    get: () => {
      throw new Error('You must wrap your component in an AxiosProvider ');
    },
  }),
);

export const useAxios = (): AxiosInstance => {
  return useContext(AxiosContext);
};

const transformPagination = (
  pagination?: PaginationProps,
):
  | {
      page: number | undefined;
      limit: number | undefined;
      offset: number;
    }
  | undefined => {
  if (!pagination) {
    return undefined;
  }

  const current = pagination.current ?? 1;

  return {
    page: current,
    limit: pagination.pageSize,
    offset: 0,
  };
};

interface ListParams {
  page?: number;
  limit?: number;
  offset?: number;
}

const useGetInfiniteList = <T, U extends BaseResponseWithPagination>(
  key: QueryKey,
  url: string,
  params?: T,
  onSuccess?: (data: InfiniteData<U>) => void,
) => {
  const axios = useAxios();
  const service = async ({ page }: { page: number }) => {
    let paginationParams: ListParams = {};
    paginationParams = { ...transformPagination({ current: page }) };
    const res = await axios.get<unknown, U, unknown>(url, {
      params: { ...params, ...paginationParams },
      paramsSerializer: (_params) => {
        return qs.stringify(_params, { arrayFormat: 'repeat' });
      },
    });
    return res;
  };

  return useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam = 1 }) => service({ page: pageParam }),
    onSuccess: (data) => onSuccess && onSuccess(data),
    getNextPageParam: (lastPage) => {
      return lastPage.data.pagination.hasNext
        ? lastPage.data.pagination.page + 1
        : undefined;
    },
    refetchOnWindowFocus: false,
  });
};

const useGetList = <T, U>(
  key: QueryKey,
  url: string,
  params?: T,
  pagination?: PaginationProps,
  options?:
    | Omit<
        UseQueryOptions<
          BaseResponseWithPagination<U>,
          unknown,
          U,
          (number | QueryKey | undefined | unknown)[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
      >
    | undefined,
): UseQueryResult<U, unknown> => {
  const axios = useAxios();
  const service = async () => {
    let paginationParams: ListParams = {};
    paginationParams = { ...transformPagination(pagination) };
    const res = await axios.get<
      unknown,
      BaseResponseWithPagination<U>,
      unknown
    >(url, {
      params: { ...params, ...paginationParams },
      paramsSerializer: (_params) => {
        return qs.stringify(_params, { arrayFormat: 'repeat' });
      },
    });
    return res;
  };

  return useQuery([...key, pagination?.current], () => service(), {
    ...options,
    keepPreviousData: true,
  });
};

const useGet = <T, U>(
  key: QueryKey,
  url: string,
  params?: T,
  options?:
    | Omit<
        UseQueryOptions<BaseResponse<U>, unknown, U, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined,
): UseQueryResult<U, unknown> => {
  const axios = useAxios();
  const service = async () => {
    const res = await axios.get<unknown, BaseResponse<U>, unknown>(url, {
      params,
      paramsSerializer: (_params) => {
        return qs.stringify(_params, { arrayFormat: 'repeat' });
      },
    });
    return res;
  };
  return useQuery(key, () => service(), options);
};

const usePost = <T, U>(
  url: string,
): UseMutationResult<BaseResponse<U>, unknown, T, unknown> => {
  const axios = useAxios();
  return useMutation(async (params: T) => {
    const res = await axios.post<T, BaseResponse<U>>(url, params);
    return res;
  });
};

const usePatch = <T, U>(
  url: string,
): UseMutationResult<BaseResponse<U>, unknown, T, unknown> => {
  const axios = useAxios();
  return useMutation(async (params: T) => {
    const res = await axios.patch<T, BaseResponse<U>>(url, params);
    return res;
  });
};

const usePatchOne = <T, U>(
  url: string,
): UseMutationResult<
  BaseResponse<U>,
  unknown,
  { id: string | number; data: T },
  unknown
> => {
  const axios = useAxios();
  return useMutation(async (params: { id: string | number; data: T }) => {
    const { id, data } = params;
    const parsedUrl = id ? `${url}/${id}` : url;
    const res = await axios.patch<T, BaseResponse<U>>(parsedUrl, data);
    return res;
  });
};

const useDelete = <T>(
  url: string,
): UseMutationResult<BaseResponse, unknown, T, unknown> => {
  const axios = useAxios();
  return useMutation(async () => {
    const res = await axios.delete<T, BaseResponse>(url);
    return res;
  });
};

const useDeleteOne = (
  url: string,
): UseMutationResult<
  BaseResponse<unknown>,
  unknown,
  {
    id: string | number;
  },
  unknown
> => {
  const axios = useAxios();
  return useMutation(async (params: { id: string | number }) => {
    const { id } = params;
    const parsedUrl = id ? `${url}/${id}` : url;
    const res = await axios.delete<null, BaseResponse>(parsedUrl);
    return res;
  });
};

export {
  useGet,
  useGetList,
  useGetInfiniteList,
  usePost,
  usePatch,
  useDelete,
  usePatchOne,
  useDeleteOne,
};

export default request;
